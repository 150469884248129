import React from "react";

import {Typography} from "@mui/material";


class AboutUs extends React.Component {
    componentDidMount() {
        document.getElementById("appBar").style.display = "none"
    }

    render() {
        return (
            <>
                <div className="bg"/>
                <div className="bg bg2"/>
                <div className="bg bg3"/>

                <div className={"center content"} style={{height: "inherit"}}>
                    <img src={require('../../../Images/ACS_logo.png')} id={"logoIMG"}/>
                    <Typography variant={"h1"}> about us</Typography>
                    <p>Buildings use about 35% of all the energy worldwide.
                        Heating, cooling and ventilation systems are the biggest contributors.
                        Often these systems aren't setup in a energy saving settings or simply aren't able to.
                        This could be better, smarter and more modern.</p>

                    <p>That's why we are gathering data to train an AI to save more energy while keeping the climate in buildings comfortable.
                       This is where you come in, with your help we can help train the AI to the preferred climate for building users.</p>

                </div>
            </>
        );
    }
}

export default AboutUs;
