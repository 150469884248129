import React from "react";
import {Link} from "react-router-dom";
import {Slider, Typography} from "@mui/material";

const sliderTypes = {
    "temperature": {step: 0.5, defaultValue: 0, min:-2, max:2,  marks:[
            {
                value: -2,
                label: '-2°C',
            },

            {
                value: -1,
                label: '-1°C',
            },
            {
                value: 0,
                label: '0°C',
            },

            {
                value: +1,
                label: '+1°C',
            },
            {
                value: +2,
                label: '+2°C',
            }
        ]},
    "humidity": {step: 2.5, defaultValue: 0, min:-10, max:10,  marks:[
            {
                value: -10,
                label: 'Cramped',
            },
            {
                value: -5,
                label: '',
            },
            {
                value: 0,
                label: 'Good',
            },

            {
                value: +5,
                label: '',
            },
            {
                value: +10,
                label: 'Dry',
            }
        ]}
}

class FormSliders extends React.Component {

    constructor(props) {
        super(props);


    }


    render() {
        function valuetext(value) {
            return `${value}°C`;
        }
        return (
            <>

                <Typography> {this.props.question}</Typography>
                <Slider
                    name={this.props.type}
                    onChange={this.props.onChangeValue}
                    aria-label={this.props.type+" silder"}
                    defaultValue={0}
                    getAriaValueText={valuetext}
                    step={sliderTypes[this.props.type].step}
                    track={false}
                    valueLabelDisplay="auto"
                    marks={sliderTypes[this.props.type].marks}
                    max={sliderTypes[this.props.type].max}
                    min={sliderTypes[this.props.type].min}
                />
            </>
        )
            ;
    }
}

export default FormSliders;
