
import React, {useState} from "react";
import {
    BottomNavigation, BottomNavigationAction,
    Box,
    Button, Card, CircularProgress, Container,
    FormControl,
    FormControlLabel,
    Input,
    InputAdornment,
    InputLabel, Paper,
    Radio,
    RadioGroup, Slider, TableFooter, TextField, ToggleButton, ToggleButtonGroup, Typography
} from "@mui/material";
import {Link, useParams} from "react-router-dom";
import API from "../../../api";
import FormToggleButtonGroup from "../../FormComponents/FormToggleButtonGroup";
import FormSliders from "../../FormComponents/FormSliders";

import DataUsageIcon from '@mui/icons-material/DataUsage';

import PolicyIcon from '@mui/icons-material/Policy';
import InfoIcon from '@mui/icons-material/Info';

import FormTextField from "../../FormComponents/FormTextField";

function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}

function getQeustions(uuid){
    return API.get("room/uuid/" + uuid)
}

class Form extends React.Component {
    id
    name

    constructor(props) {
        super(props);
        this.uuid = this.props.params.uuid

        this.state = {
            name: "",
            gender: "Female",
            age: 69,
            temperature: 0,
            humidity: 0,
            additional: "",
            loading: true,
            error: false,
            displayGenderAge: true
        }


    }

    getCookie(name) {
        var nameEQ = name + "=";
        console.log(name)
        console.log(document.cookie.split("=")[1])

        if(document.cookie.split("=")[1] === name){
            return true
        }
        return null;
    }
    componentDidMount() {
        document.getElementById("appBar").style.display = "none"
        const genderAge = JSON.parse(localStorage.getItem("genderAge"))
        console.log(this.getCookie("from "+this.uuid))
        if(this.getCookie( this.uuid) === true){
            this.setState({error: true, errorType: "Filled in form too recently."})
        } else{
        if (genderAge) {
            this.setState({gender: genderAge.gender, age: genderAge.age, displayGenderAge: false})
        }
        getQeustions(this.uuid).then(response => {
            this.id = response.data.id
            this.state.name = response.data.name
            this.setState({questions : response.data.questions, loading:false})
        }).catch(error => {
            // your error handling goes here
            this.setState({error: true, errorType: "Room not found."})
            console.log("ive got an error")
        })}



    }

    render() {


        const handleChange = (e) => {

            this.setState((prevState) => ({
                ...prevState,
                [e.target.name]: e.target.value,

            }))

        }

        const handleSubmit = (e) => {
            e.preventDefault()

            let data = {
                "answers": [
                    {
                        "id": 1,
                        "value": this.state.gender
                    },
                    {
                        "id": 2,
                        "value": this.state.age
                    },
                    {
                        "id": 3,
                        "value": this.state.temperature.toString()
                    },
                    {
                        "id": 4,
                        "value": this.state.humidity.toString()
                    }

                ]
            }


            API.post("room/" + this.id + "/answer", data).then((response) => {
                if (response.status === 200) {
                    window.open("/thankyou","_self")
                    localStorage.setItem("genderAge", JSON.stringify({
                        gender: this.state.gender,
                        age: this.state.age
                    }));
                    setCookie("form "+ this.uuid, this.uuid, 1 )
                } else {
                    console.log("smth whent terribly wrong")
                }
            })

        }
        function setCookie(name,value,hours) {
            var expires = "";
            if (hours) {
                var date = new Date();
                date.setTime(date.getTime() + (hours*60*60*1000));
                expires = "; expires=" + date.toUTCString();
                console.log("yes")
            }
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }

        function getQuestion(question, state) {
            let sQuestion =question.input.split("-")


            switch (sQuestion[0]) {
                case "toggle":
                    switch (sQuestion[1]) {
                        case "gender":

                            return <FormToggleButtonGroup display={state.displayGenderAge} type={"gender"} value={state.gender} question={question.question}
                                                          onChangeValue={handleChange}/>

                    }
                    break;
                case "text":
                    switch (sQuestion[1]) {
                        case "age":

                            return <FormTextField display={state.displayGenderAge} type={"age"} value={state.age} question={question.question}
                                                  onChangeValue={handleChange}/>
                        case "additional":
                            return <FormTextField type={"additional"} value={state.additional} question={question.question}
                                                  onChangeValue={handleChange}/>
                        default:
                            break;

                    }
                    break;
                case "slider":
                    switch (sQuestion[1]) {
                        case "humidity":
                            return <FormSliders type={"humidity"} value={state.humidity} question={question.question}
                                                onChangeValue={handleChange}/>

                        case "temperature":
                            return <FormSliders type={"temperature"} value={state.temperature} question={question.question}
                                                onChangeValue={handleChange}/>
                        default:
                            break;

                    }
                    break;
                default:
                    break;
            }
        }

        return (
            <>
                <div className="bg"/>
                <div className="bg bg2"/>
                <div className="bg bg3"/>
                <form className={"center content"}
                      style={{height: "inherit", justifyContent: "space-between", overflowY: "auto"}}
                      onSubmit={handleSubmit}>
                    {this.state.error ? <Container ><h1>{this.state.errorType}</h1></Container> :<>
                        {!this.state.loading ? <>

                                <>
                                    <img src={require('../../../Images/ACS_logo.png')} id={"logoIMG"}/>
                                    <Typography variant={"h3"}> Room {this.state.name} </Typography>
                                    {!this.state.loading ? this.state.questions.map(q => {
                                        return getQuestion(q, this.state)
                                    }) : <p>no questions found</p>}



                                    <Button variant="contained" color={"secondary"} type="submit" fullWidth>Submit</Button>
                                    <br/>
                                </> </> :
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            padding={2}
                        >
                            <CircularProgress/>
                        </Box>

                    } </>}
                    <BottomNavigation showLabels sx={{width: "100% ", bottom: 0, background: "none", color: "blue"}}>
                        <BottomNavigationAction label={"About us"} icon={<InfoIcon/>} component={Link} to={"/"}/>
                        <BottomNavigationAction label={"Privacy policy"} href={"https://climatics.nl/privacy-policy"} icon={<PolicyIcon/>}/>
                        <BottomNavigationAction label={"What we do"} href={"https://acs-buildings.com/"} icon={<DataUsageIcon/>}/>
                    </BottomNavigation>
                </form>



            </>


        );
    }
}


export default withParams(Form);
