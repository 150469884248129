import React from "react";

import {Typography} from "@mui/material";


class ThankYouPage extends React.Component {
    componentDidMount() {
        document.getElementById("appBar").style.display = "none"
    }

    render() {
        return (
            <>
                <div className="bg"/>
                <div className="bg bg2"/>
                <div className="bg bg3"/>

                <div className={"center content"} style={{height: "inherit"}}>
                    <img src={require('../../../Images/ACS_logo.png')} id={"logoIMG"}/>
                    <Typography>ASC and all building occupants would like to thank you for helping to improve the
                        temperature!</Typography>


                </div>
            </>
        );
    }
}

export default ThankYouPage;
