import React from 'react';
import API from "../../api";

export function Logout(){
    const payload = {
        session: localStorage.getItem('session')
    }

    API.post("user/logout", payload)

    localStorage.removeItem('session');

    window.location.href = '/';
    return <></>;
}