import React from 'react';
import {Button, Paper, Container, TextField} from "@mui/material";
import API from "../../api";

class Login extends React.Component {
    login(event) {
        event.preventDefault();

        const payload = {
            email: document.getElementById('email').value,
            password: document.getElementById('password').value
        }

        API.post("user/login", payload).then(response => {
            if (response.status == 200) {
                localStorage.setItem('session', response.data.session)
                window.location.href = '/admin';
            }
        }).catch(error => {
            if(error.response.status === 403){
                alert("Invalid credentials.");
                window.location.href = '/login';
            }else{
                alert("An error accord while logging in.");
                window.location.href = '/login';
            }
        })
    }

    componentDidMount() {
        document.getElementById("appBar").style.display = "none"
    }

    render() {
        return (
            <>
                <div className="bg"/>
                <div className="bg bg2"/>
                <div className="bg bg3"/>


                <form className="form content"
                      style={{height: "50vh", justifyContent: "center", overflowY: "auto"}}  >
                    <img src={require('../../Images/ACS_logo.png')} id={"logoIMG"} style={{paddingBottom: "3vh"}}/>
                    <TextField
                        labelText="Email"
                        id="email"
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="text"
                    />
                    <TextField
                        labelText="Password"
                        id="password"
                        formControlProps={{
                            fullWidth: true
                        }}
                        type="password"
                        style={{paddingBottom: "2vh"}}
                    />

                    <Button variant="contained" color={"secondary"} type="button" fullWidth
                            onClick={this.login}>
                        Log in
                    </Button>
                </form>


            </>
        );
    }
}

export default Login;