import React from "react";
import {Link} from "react-router-dom";
import {InputLabel, TextField, Typography} from "@mui/material";

const textFieldTypes = {
    "age": {

        type: "number",
        variant: "outlined",
        required: true,
        focused: true,
        InputProps: {inputProps: {max: 130, min: 5}}
    },
    "additional": {

        multiline: true,
        rows: 4,
        variant: "outlined",
        required: false,
        focused: true
    }
}

class EmptyComponent extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <>
                {this.props.display ? <>
                <Typography className={this.props.type}> {this.props.question}</Typography>

                <TextField name={this.props.type} className={this.props.type}
                           required={textFieldTypes[this.props.type].required}
                           value={this.props.value}
                           onChange={this.props.onChangeValue}
                           InputProps={textFieldTypes[this.props.type].InputProps}
                           type={textFieldTypes[this.props.type].type}

                           variant={textFieldTypes[this.props.type].variant}
                           color={"primary"}
                           
                           focused={textFieldTypes[this.props.type].focused}
                           multiline={textFieldTypes[this.props.type].multiline}
                           rows={textFieldTypes[this.props.type].rows}
                /> </>: <></>}



            </>
        );
    }
}

export default EmptyComponent;
