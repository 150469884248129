import React from "react";
import {Link} from "react-router-dom";
import {Button, Divider, List, ListItem, ListItemText, Typography} from "@mui/material";
import {Marker, Popup} from "react-leaflet";
import API from "../../../api";
import SearchField from "../../Utilities/SearchField";


class PopupComponent extends React.Component {
    constructor(props) {
        super(props);
        this.name = props.name;
        this.buildingID = props.id;
        this.state = {
            loading: true,
            response: [],
            rooms: []
        }
    }

    componentDidMount() {
        API.get("building/" + this.buildingID + "/rooms").then(response => {
            this.setState({loading: false, response: response.data})


            response.data.map(room => {
                    this.state.rooms.push(room.name)
                }
            )
        })

    }

    render() {
        return (
            <>
                <Popup className='Building '>
                    <div className={"popup"} style={{minWidth: "200px"}}>
                        <Typography variant={"h1"}>{this.name}</Typography>
                        <SearchField classText="popupSearch"/>
                        <List id='popupList'>
                            <Divider component="li" sx={{borderBottomWidth: 2}}/>
                            {this.state.response.map((room, index) => {
                                return <div className="Button popupSearch" id={room.name.toLowerCase()} key={index}>
                                    <ListItem
                                        variant="outlined" component={Link}
                                        to={"/admin/" + this.buildingID + "/room/" + room.id}>
                                        <ListItemText primary={room.name} className={"SideBarButtons"}/>
                                    </ListItem>
                                    <Divider component="li" sx={{borderBottomWidth: 2}}/></div>
                            })}

                        </List>
                    </div>
                </Popup>
            </>
        );
    }
}

export default PopupComponent;
