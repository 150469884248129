import React from "react";
import {Link} from "react-router-dom";
import {
    LineChart,
    Tooltip,
    XAxis,
    Legend, YAxis, CartesianGrid, Line, BarChart, Bar, ResponsiveContainer
} from 'recharts';
import {Container, Paper, Typography} from "@mui/material";

class ChartFactory extends React.Component {
    chartData
    charts = []
    type
    range
    name
    constructor(props) {
        super(props);
        this.chartData = this.props.data
        this.state = {
            chartData: this.props.data
        }
        this.type = props.type
        this.range = props.range
        this.name = props.name
        if (this.chartData === undefined) {
            return
        }
        this.createCharts()


    }

    createCharts() {
        var data = this.state.chartData


        switch (this.type) {

            case "linechart":
                this.charts.push(this.lineChart(data, 25, this.name))
                break
            case "barchart":
                this.charts.push(this.barChart(data, this.name))
                break
            default:
                break

        }


    }

    barChart(data, name) {

        return <Paper sx={{width: "100%", height: "100%"}}>
            <Typography variant={"h4"} textAlign={"center"}>{name}</Typography>
            <ResponsiveContainer width="100%" height="85%">
                <BarChart  data={data.timestamps}>
                    <Bar dataKey="Responses" fill="#8884d8"/>
                    <XAxis dataKey={"timestamp"}/>
                    <Legend/>
                    <Tooltip/>

                </BarChart>
            </ResponsiveContainer>
        </Paper>
    }

    lineChart(data, range, name) {
        console.log(name)
        return <Paper sx={{width: "100%", height: "100%", minWidth: "300px"}}>
            <Typography variant={"h4"} textAlign={"center"}>{name}</Typography>
            <ResponsiveContainer width="100%" height="85%">
                <LineChart
                    data={data.timestamps}
                    margin={{
                        top: 10,
                        right: 30,
                        left: -10,
                        bottom: 10,
                    }}
                >
                    <CartesianGrid strokeDasharray={range}/>
                    <XAxis dataKey={"timestamp"}/>
                    <YAxis domain={[15, range]}/>
                    <Tooltip/>
                    <Legend/>
                    <Line type="monotone" dataKey={"Desired"} stroke="#8884d8"/>
                    <Line type="monotone" dataKey={"In the room"} stroke="#000000"/>

                </LineChart>
            </ResponsiveContainer>
        </Paper>
    }

    render() {
        return (
            <>
                {this.charts}
            </>
        );
    }
}

export default ChartFactory;
