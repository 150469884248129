import React from 'react';
import {Navigate, Outlet} from 'react-router-dom';
import API from "../../api";

const AdminRoute = ({component: Component, ...rest}) => {
    if (isLoggedIn()) {
        return <Outlet />
    }

    return <Navigate to='/login'/>
};

function isLoggedIn() {
    const session = localStorage.getItem('session');

    if (session === null) {
        return false;
    }

    const payload = {
        session: session
    }

    API.post("user/session", payload).catch(error =>{
        if(error.response.status === 403){
            window.location.href = '/login';
            return false;
        }
    })
    return true;
}

export default AdminRoute;
