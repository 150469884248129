import React from "react";
import {Link, useParams} from "react-router-dom";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    Container, Divider,
    Grid, List,
    ListItem,
    ListItemText,
    Paper,
    Typography
} from "@mui/material";
import SearchField from "../../Utilities/SearchField";
import API, {getApiBaseUrl} from "../../../api";
import {apiBaseUrl} from '../../Utilities/EnvHelper'

import ChartFactory from "../../Utilities/ChartFactory";
import api from "../../../api";


function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}

class Room extends React.Component {
    name;
    tempChart = []
    currentTemp = "Not available"
    currentHum= "Not available"
    COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

    constructor(props) {
        super(props);
        this.id = this.props.params.id
        this.buildingId = this.props.params.buildingId
        this.buildingName = this.props.params.buildingName
        this.uuid = "None"
        this.state = {
            bogusValue: "banana",
            SideBarOpen: true,
            rooms: [],
            loading: true,
            feedback: []
        }


    }


    // https://stackoverflow.com/questions/1053843/get-the-element-with-the-highest-occurrence-in-an-array
    mode(array) {
        if (array.length == 0)
            return null;
        var modeMap = {};
        var maxEl = array[0], maxCount = 1;
        for (var i = 0; i < array.length; i++) {
            var el = array[i];
            if (modeMap[el] == null)
                modeMap[el] = 1;
            else
                modeMap[el]++;
            if (modeMap[el] > maxCount) {
                maxEl = el;
                maxCount = modeMap[el];
            }
        }
        return maxEl;
    }

    componentDidMount() {
        document.getElementById("appBar").style.display = "block"
        API.get("building/" + this.buildingId + "/rooms").then(response => {

            this.setState({rooms: response.data})

        })
        API.get("building/" + this.buildingId).then(response => {

            this.buildingName = response.data.name

        })
        API.get("room/" + this.id).then(response => {

            this.uuid = response.data.qr_uuid
            this.name = response.data.name


        })
        let date = new Date();
        var dd = String(date.getDate()).padStart(2, '0');
        var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = date.getFullYear();

        let today = yyyy + '-' + mm + '-' + dd + " 00:00:00"

        let questionBuckets = []
        let accualTemps = []
        API.get("room/" + this.id + "/datapoints?start_datetime=" + today).then(respone => {
            accualTemps = respone.data


        }).then(e => {
        API.get("room/" + this.id + "/feedback?start_datetime=" + today).then(response => {



            if(accualTemps.length === 0){
                this.setState({loading: false, feedback: questionBuckets})
                return
            }
            this.currentTemp = accualTemps[accualTemps.length -1].value

            let val = 0
            response.data.map((awnser, index) => {


                if (awnser.is_valid === 1) {
                    let key = awnser.question

                    if (awnser.question === "By what amount do you want to change the temperature?") {
                        let timeStamp = awnser.timestamp.substr(11, 4) + "0"

                        if (questionBuckets.hasOwnProperty(key)) {
                            if (questionBuckets[key]["timestamps"].some(o => o.hasOwnProperty("timestamp") && o["timestamp"] === timeStamp)) {
                                questionBuckets[key]["timestamps"].find(o => o.hasOwnProperty("timestamp") && o["timestamp"] === timeStamp)["values"].push(awnser.value)
                            } else {

                                questionBuckets[key]["timestamps"].push({
                                    "timestamp": timeStamp,
                                    "values": [awnser.value],
                                    "In the room": accualTemps[val].value
                                })
                                val++
                            }
                        } else {

                            questionBuckets[key] = {"calculation_type": "mean"}
                            questionBuckets[key]["timestamps"] = [{"timestamp": timeStamp, "values": [awnser.value], "In the room": accualTemps[val].value}]
                            val++
                        }

                    } else if (awnser.question === "By what amount do you want to change the humidity?") {
                        let timeStamp = awnser.timestamp.substr(11, 4) + "0"

                        if (questionBuckets.hasOwnProperty(key)) {
                            if (questionBuckets[key]["timestamps"].some(o => o.hasOwnProperty("timestamp") && o["timestamp"] === timeStamp)) {
                                questionBuckets[key]["timestamps"].find(o => o.hasOwnProperty("timestamp") && o["timestamp"] === timeStamp)["values"].push(awnser.value)
                            } else {
                                questionBuckets[key]["timestamps"].push({
                                    "timestamp": timeStamp,
                                    "values": [awnser.value],
                                    "In the room": "Not availble"
                                })
                            }
                        } else {

                            questionBuckets[key] = {"calculation_type": "mean"}
                            questionBuckets[key]["timestamps"] = [{"timestamp": timeStamp, "values": [awnser.value], "In the room": "Not availble"}]

                        }
                    } else {
                        if (questionBuckets.hasOwnProperty(key)) {

                            questionBuckets[key].push(awnser)
                        } else {
                            questionBuckets[key] = [awnser]
                        }
                    }
                }

            })


            for (let question in questionBuckets) {
                question = questionBuckets[question]
                switch (question["calculation_type"]) {
                    case "mean":
                        for (let timestamp in question["timestamps"]) {
                            timestamp = question["timestamps"][timestamp]
                            timestamp["Desired"] = parseFloat( timestamp["In the room"]) +parseFloat( this.mode(timestamp["values"]))
                            timestamp["Responses"] = timestamp["values"].length
                        }

                        break;
                    default: break;
                }
            }
            console.log(questionBuckets)
            this.setState({loading: false, feedback: questionBuckets})

        })
        })

    }

        render()
        {


            function downloadQR(id) {
                const link = document.createElement("a");
                link.target = "_blank";
                link.href = "https://" + apiBaseUrl() + "/api/v1/room/" + id + "/qr?download=1"
                // link.href = "https://" + apiBaseUrl() + "/api/v1/room/" + id + "/qr"

                link.click()
            }
            return (
                <>
                    {!this.state.loading ? <>
                            {this.state.error ? <div>{this.state.error}</div> :
                                <>
                                    <div className="bg"/>
                                    <div className="bg bg2"/>
                                    <div className="bg bg3"/>
                                    <div className='Container'>

                                        <Paper sx={{
                                            display: {xs: 'none', md: 'flex'},
                                            flexDirection: "column",
                                            backgroundColor: "primary.main",
                                            margin: "2px"
                                        }} id='SideBar'>

                                            <SearchField classText="sidebarSearch"/>

                                            <List className="SideBarButtons">
                                                <Divider component="li"/>
                                                {this.state.rooms.map((room, index) => {
                                                    return <div className={"sidebarSearch"} id={room.name} key={index}>
                                                        <ListItem dense className="Button "
                                                                  variant="outlined" onClick="click"
                                                                  component={Link}
                                                                  to={"/admin/" + this.buildingId + "/room/" + room.id}>
                                                            <ListItemText primary={room.name}/>

                                                        </ListItem>
                                                        <Divider component="li"/>
                                                    </div>
                                                })}
                                            </List>

                                        </Paper>
                                        <Paper className={"dashboard"} style={{
                                            display: "flex", flexGrow: 1,
                                            overflowX: "auto",
                                            justifyContent: "center",

                                        }}>

                                            <Typography sx={{
                                                background: " #0E7799",
                                                width: "100%",
                                                borderRadius: "4px",
                                                color: "white",
                                                textAlign: "center",
                                                margin: "2px 2px 0 0"
                                            }}
                                                        variant="h4"> {this.name} in {this.buildingName}</Typography>


                                            <Grid container spacing={3} sx={{justifyContent: "center"}}>

                                                <Grid item md={5}>
                                                    <ChartFactory
                                                        data={this.state.feedback["How are you experiencing the humidity?"]}
                                                        type={"linechart"}
                                                        name={"Humidity"}
                                                        range={35}
                                                    />
                                                </Grid>
                                                <Grid item md={5}>
                                                    <ChartFactory
                                                        data={this.state.feedback["By what amount do you want to change the temperature?"]}
                                                        type={"barchart"}
                                                        name={"Amount of responses"}
                                                    />
                                                </Grid>



                                                <Grid item md={5}>
                                                    <ChartFactory
                                                        data={this.state.feedback["By what amount do you want to change the temperature?"]}
                                                        type={"linechart"}
                                                        name={"Temperature"}
                                                        range={35}
                                                    />
                                                </Grid>
                                                <Grid item md={5} sx={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    justifyContent: "space-between",
                                                    alignItems: "center"
                                                }}>
                                                    <div style={{width: "100%", display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                                    <Paper sx={{padding: "10px", textAlign: "center", margin:"2px"}}>
                                                        <Typography variant={"h4"}>Current temperature</Typography>
                                                        <Typography variant={"h6"}  textAlign={"center"}><Paper> {this.currentTemp}</Paper></Typography>

                                                    </Paper>
                                                    <Paper sx={{padding: "10px", textAlign: "center", margin:"2px" }}>
                                                        <Typography variant={"h4"}>Current humidity</Typography>
                                                        <Typography variant={"h6"} textAlign={"center"}><Paper> {this.currentHum}</Paper></Typography>
                                                    </Paper>
                                                    <Paper sx={{padding: "10px",  textAlign: "center", margin:"2px"}}>
                                                        <Typography variant={"h4"}> Admin </Typography>
                                                        <div style={{display: "flex", justifyContent: "space-around"}}>
                                                            <Button variant="outlined" component={Link}
                                                                    to={"/form/" + this.uuid}>form</Button>
                                                            <Button variant="outlined"
                                                                    onClick={() =>{downloadQR(this.id)}}>QR</Button>
                                                            <Button variant="outlined" component={Link}
                                                                    to={"/admin/poster/" + this.id}>Poster</Button>
                                                        </div>
                                                    </Paper></div>
                                                </Grid>



                                            </Grid>
                                        </Paper>
                                    </div>
                                </>} </> :
                        <Box
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            padding={2}
                        >
                            <CircularProgress/>
                        </Box>

                    }
                </>
            );
        }
    }

    export
    default

    withParams(Room);

