const isLive = function() {
    let live = process.env.REACT_APP_ADC_PRODUCTION;
    if (live != null && live === 'true') {
        return true;
    } else {
        return false;
    }
};

const apiBaseUrl = function () {
    if (isLive()) {
        return process.env.REACT_APP_API_BASE_URL
    } else {
        return 'adc-api.itscoffee.nl'
    }
};

exports.isLive = isLive;
exports.apiBaseUrl = apiBaseUrl;