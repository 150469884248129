import React from "react";
import {Link, useParams} from "react-router-dom";
import {apiBaseUrl} from "../../Utilities/EnvHelper";
import html2canvas from 'html2canvas';
import { jsPDF } from "jspdf";

function withParams(Component) {
    return props => <Component {...props} params={useParams()}/>;
}
class Poster extends React.Component {
    images = [require("../../../Images/Poster_bone.png"), require("../../../Images/Poster_cool.png"), require("../../../Images/Poster_hot.png"), require("../../../Images/Poster_superpowers.png")]
    qr = ""
    constructor(props) {
        super(props);

        this.qr = "https://" + apiBaseUrl() + "/api/v1/room/" + this.props.params.id + "/qr"

    }
    componentDidMount() {
        const input = document.getElementById('divToPrint');
        html2canvas((input), {
            logging: true,
            profile: true,
            useCORS: true}).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF();

            pdf.addImage(imgData, 'JPEG', 0, 0);

            pdf.save("poster.pdf");
        })
        ;
    }

    render() {
        return (
            <div id={"divToPrint"}>
                <img src={this.images[Math.floor(Math.random() * this.images.length)]} style={{ position: "relative", top: "0", left: "0", width: "800px", height: "1100px"}}/>
                <img src={this.qr} style={{ position: "absolute", zIndex:"2", top: "540px", left: "170px", width: "460px"}}/>
            </div>
        );
    }
}
export default withParams(Poster);

