import React from "react";
import {Link} from "react-router-dom";
import {ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";

const toggleOptions = {
    "gender": {values:["Female",  "Male",  "other",  "I'd rather not say"]}
}

class FormToggleButtonGroup extends React.Component {

    constructor(props) {
        super(props);


    }


    render() {
        return (
            <>
                {this.props.display ? <>
                <Typography className={this.props.type}> {this.props.question} </Typography>

                <ToggleButtonGroup
                    name={this.props.type}
                    color="primary"
                    value={this.props.value}

                    onChange={this.props.onChangeValue}
                    aria-label={this.props.type}
                    className={this.props.type}
                    exclusive
                >
                    {toggleOptions[this.props.type].values.map(value => {
                        return <ToggleButton name={this.props.type} className={"Toggle " + this.props.type}
                                             value={value} sx={{borderColor: "#0D5C76", color: "black ", borderRadius: "4px" }}>{value}</ToggleButton>
                    })}

                </ToggleButtonGroup> </> : <></>}
            </>
        )
            ;
    }
}

export default FormToggleButtonGroup;
