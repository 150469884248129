import './App.css';
import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Link,
    useParams
} from "react-router-dom";
import GreycliffCF from './Fonts/GreycliffCF-Regular.woff2';
import Form from "./Components/Pages/Public/Form";
import {Navigate} from "react-router-dom";

import {
    AppBar, Box, Button,
    Container,
    createTheme,
    CssBaseline, IconButton, Menu, MenuItem,
    Paper,
    ThemeProvider,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import MapPage from "./Components/Pages/Admin/MapPage";
import ThankYouPage from "./Components/Pages/Public/ThankYouPage";
import Room from "./Components/Pages/Admin/Room";
import Poster from "./Components/Pages/Admin/Poster";
import Buildings from "./Components/Pages/Admin/Buildings";
import AboutUs from "./Components/Pages/Public/AboutUs";
import './Fonts/GreycliffCF-Regular.woff2'

import {Logout} from "./Components/Authentication/Logout";
import Login from "./Components/Authentication/Login";
import AdminRoute from "./Components/Authentication/AdminRoute";
import Settings from "./Components/Pages/Admin/Settings";
import {Menu as MenuIcon} from "@mui/icons-material";

function App() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const pages = ['Map', 'Buildings', 'Logout'];
    const pageMap = {'Map': '/admin', 'Buildings': 'admin/buildings', 'Logout': '/logout'};
    const theme = createTheme({
        palette: {
            primary: {
                main: "#0D5C76",
            },
            secondary: {
                main: "#1EA469",
            },
            text: {
                main: "#ffffff",
            },
        },
        typography: {
            fontFamily: 'GreycliffCF',
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: `
       
      `,
            },
        },
    });
    theme.spacing(4)
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    function displayMenubar(type) {
        switch (type) {
            case 'desktop':
                return pages.map((page) => (

                    <Typography
                        variant="h6"
                        noWrap
                        onClick={handleCloseNavMenu}
                        sx={{
                            mr: 2,
                            display: {xs: 'none', md: 'flex'},
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                        component={Link}
                        to={pageMap[page]}
                    >
                        {page}
                    </Typography>
            ))
            case 'mobile':
                return pages.map((page) => (
                    <MenuItem key={page} onClick={handleCloseNavMenu} component={Link} to={pageMap[page]}>
                        <Typography textAlign="center">{page}</Typography>
                    </MenuItem>
                ))
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <Router>

                {/*<CssBaseline/>*/}
                <AppBar position="static" id="appBar">
                    <Container maxWidth="xl">
                        <Toolbar disableGutters variant="dense">
                            {/*<Paper  sx={{marginRight: "5px", width:"min-content", height: "min-content"}}>*/}
                            {/*    <img src={require("./Images/ACS_logo.png")} width={"80px"} alt="logo" />*/}
                            {/*</Paper>*/}
                            <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleOpenNavMenu}
                                    color="inherit"
                                >
                                    <MenuIcon/>
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorElNav}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    }}
                                    keepMounted
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'left',
                                    }}
                                    open={Boolean(anchorElNav)}
                                    onClose={handleCloseNavMenu}
                                    sx={{
                                        display: {xs: 'block', md: 'none'},
                                    }}
                                >

                                    {displayMenubar("mobile")}
                                </Menu>

                            </Box>
                            <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                                {displayMenubar("desktop")}
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>

                <Routes>
                    <Route path="/" element={<AboutUs/>}/>
                    <Route path="/thankYou" element={<ThankYouPage/>}/>
                    <Route path="/form/:uuid" element={<Form params={useParams()}/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/logout" element={<Logout/>}/>
                    <Route path="*" element={<Navigate replace to="/"/>}/>
                    <Route element={<AdminRoute/>}>
                        <Route path="/admin" element={<MapPage/>}/>
                        <Route path="/admin/settings" element={<Settings/>}/>

                        <Route path="/admin/:buildingId/room/:id" element={<Room params={useParams()}/>}/>
                        <Route path="/admin/buildings" element={<Buildings/>}/>}/>
                        <Route path="/admin/poster/:id" element={<Poster params={useParams()}/>}/>
                    </Route>
                </Routes>
            </Router>
        </ThemeProvider>
    );
}

export default App;
