import React from "react";
import {Link} from "react-router-dom";
import API from "../../../api"
import {Button, CardActions, CardContent, Container, Paper, Typography} from "@mui/material";
import SearchField from "../../Utilities/SearchField";

class Buildings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            buildings: []
        }
    }

    componentDidMount() {
        this.fetchBuildings()

    }

    fetchBuildings() {
        API.get('buildings').then(response => {
            this.setState({buildings: response.data})

        })

    }

    render() {
        return (
            <>
                <SearchField classText="buildingCard"/>
                <Container maxWidth={"xl"} sx={{display: "flex", flexWrap: "wrap", justifyContent: "center"}}>
                    <div className="bg"/>
                    <div className="bg bg2"/>
                    <div className="bg bg3"/>
                    {this.state.buildings.map(building => (

                        <Paper className="buildingCard" id={building.name.toLowerCase()} elevation={8}  >
                                <CardContent>
                                    <Typography variant="h5" component="h2">
                                        {building.name}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Address: {building.address}
                                    </Typography>

                                    <Typography variant="body2" component="p">
                                        Longitude: {building.longitude}
                                    </Typography>
                                    <Typography variant="body2" component="p">
                                        Latitude: {building.latitude}
                                    </Typography>


                                </CardContent>
                                <CardActions>
                                    <Button variant="contained" color="primary" component={Link}
                                            to={"/admin/" + building.id + "/room/" + building.first_room_id}>Open</Button>
                                </CardActions>

                        </Paper>

                    ))}
                </Container>
            </>
        );
    }


}

export default Buildings;
