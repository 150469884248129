import React from "react";

import {Box, InputAdornment, TextField} from "@mui/material";

import SearchIcon from '@mui/icons-material/Search';


class SearchField extends React.Component {
    constructor(props) {
        super(props);
        this.classText = props.classText
    }

    render() {
        return (
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                margin="4px"
            >

                <TextField

                    size="small"
                    label="Search"
                    variant="standard"
                    color="text"
                    focused

                    onChange={(e) => {


                        let rooms = document.getElementsByClassName(this.classText)
                        
                        let roomsToFind = document.querySelectorAll(`[id*='${e.target.value.toLowerCase()}']`)


                        if (e.target.value !== "") {
                            for (let i = 0; i < rooms.length; i++) {
                                rooms[i].style.display = "none"
                            }
                            for (let i = 0; i < roomsToFind.length; i++) {
                                roomsToFind[i].style.display = "block"
                            }
                        } else {
                            for (let i = 0; i < rooms.length; i++) {
                                rooms[i].style.display = "block"
                            }
                        }


                    }}
                    InputProps={{

                        endAdornment: (
                            <InputAdornment position="end" >
                                <SearchIcon color={"text"}/>
                            </InputAdornment>
                        ),
                        style: { color: 'white'}
                    }}
                />

            </Box>
        );
    }
}
export default SearchField;
