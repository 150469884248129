import React, {useRef} from "react";
import {MapContainer, TileLayer, Marker} from 'react-leaflet';

import {
    Box,
    Button,
    Container,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Paper,
    setRef,
    Typography
} from "@mui/material";
import PopupComponent from "./PopupComponent";
import * as L from "leaflet";
import icon from '../../../Images/MapMarker.png';
import API from "../../../api"
import SearchField from "../../Utilities/SearchField";



const position = [53.2188887, 6.5660754]

const MarkerIcon = L.icon({
    iconUrl: icon,

    iconSize: [20, 34], // size of the icon
    iconAnchor: [11, 47], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -40] // point from which the popup should open relative to the iconAnchor
});

function BuildingButtons(props) {


    const zoomTo = (event, lat, long, refs, buildingName) => {
        props.map.current.setView([lat, long], 20)
        refs[buildingName].openPopup([lat, long])


    }

    return (
        <>
            <Divider component="li" sx={{borderBottomWidth: 2}}/>
            {props.buildings.map((building, index) => {
                return <div id={building.name.toLowerCase()} key={index} className="Button sidebarSearch">
                    <ListItem
                        onClick={event => zoomTo(event, building.latitude, building.longitude, props.markerRefs, building.name)}>
                        <ListItemText primary={<Typography variant={"h6"}>{building.name}</Typography>}
                                      secondary={<Typography variant={"caption"}>{building.address}</Typography>}
                                      color={"white"}/>

                    </ListItem>
                    <Divider component="li" sx={{borderBottomWidth: 3, width: "100%"}}/>
                </div>
            })}
        </>
    );
}


class MapPage extends React.Component {
    data = [];
    response = [];
    markerRefs = {}
    mapURL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"

    constructor(props) {
        super(props);
        this.state = {
            markers: [],
            loading: true,
            response: [],
            buildings: [],
            SideBarOpen: true
        }
        var settings = localStorage.getItem("settings")
        if (settings != null) {
            this.loadMapType(JSON.parse(settings).mapType)
        }

        this.mapRef = React.createRef();
        this.addMarkers = this.addMarkers.bind(this);

    }


    componentDidMount() {
        API.get("buildings").then(response => {

            this.data = this.createMarkers(response.data)

            this.setState({loading: false, response: response.data})
            this.addMarkers()
        })

    }

    loadMapType(mapType) {
        var URL;
        if (mapType === null) {
            return
        }
        switch (mapType) {

            case "satellite":
                URL = "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}";
                break;
            case "terrain":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/terrain/{z}/{x}/{y}{r}.png";
                break;
            case "topo":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/topo/{z}/{x}/{y}{r}.png";
                break;
            case "watercolor":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/watercolor/{z}/{x}/{y}{r}.png";
                break;
            case "toner":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png";
                break;
            case "tonerlite":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lite/{z}/{x}/{y}{r}.png";
                break;
            case "toner-background":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-background/{z}/{x}/{y}{r}.png";
                break;
            case "toner-hybrid":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-hybrid/{z}/{x}/{y}{r}.png";
                break;
            case "toner-labels":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-labels/{z}/{x}/{y}{r}.png";
                break;
            case "toner-lines":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/toner-lines/{z}/{x}/{y}{r}.png";
                break;
            case "landscape":
                URL = "https://stamen-tiles-{s}.a.ssl.fastly.net/landscape/{z}/{x}/{y}{r}.png";
                break;
            case "dark_matter":
                URL = "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png";
                break;
            default:
                URL = "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
                break;

        }

        this.mapURL = URL
        console.log(this.mapURL)
    }

    createMarkers(buildingList) {
        var markers = [];

        buildingList.map((building, index) => {
                this.state.buildings.push(building.name)
                this.markerRefs[building.name] = React.createRef()

                markers.push(
                    <Marker ref={(r) => {
                        this.markerRefs[building.name] = r
                    }} key={index} position={[building.latitude, building.longitude]} icon={MarkerIcon}>
                        <PopupComponent id={building.id}/>
                    </Marker>
                )

            }
        )
        return markers

    }

    addMarkers() {

        const markers = this.data

        this.setState({
            markers: markers

        })
    }
    handleOpenSidebar(){
        document.getElementById('SideBar').style.display = "block"
        document.getElementById('SideBarButton').style.display = "none"

    }
    handleCloseSidebar() {
        document.getElementById('SideBar').style.display = "none"
        document.getElementById('SideBarButton').style.display = "block"
    }

    render() {

        return (

            <div className='Container'>

                <Container id='SideBar'
                           sx={{display: "flex", flexGrow: "1"}}>
                    <Paper
                        sx={{display: "flex", flexDirection: "column", backgroundColor: "primary.main", margin: "2px", flexGrow: 1}}>

                        <Container sx={{display: "flex", justifyContent: "center !important", alignItems: "center"}}>
                        <SearchField classText="sidebarSearch"/>
                        <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                            <Button onClick={this.handleCloseSidebar}
                                    style={{backgroundColor: '#0D5C76',
                                        color:"white", border: "2px solid white", height: "min-content"}}> Close </Button>
                        </Box>

                        </Container>
                        <List sx={{
                            width: '100%',

                            backgroundColor: 'primary', flexGrow: 1
                        }} className="SideBarButtons">
                            <BuildingButtons buildings={this.state.response} markerRefs={this.markerRefs}
                                             map={this.mapRef}/>
                        </List>
                    </Paper>
                </Container>
                <Button id={'SideBarButton'} onClick={this.handleOpenSidebar} sx={{
                    position: "fixed",
                    backgroundColor: '#0D5C76',
                    color:"white",
                    zIndex: "1000",
                    bottom: "20px",
                    width: "13px",
                    borderRadius: "0 4px 4px 0",
                    padding: "0px",
                    display: "none"
                }}>=</Button>
                <MapContainer
                    ref={this.mapRef}
                    center={position}
                    zoom={13}
                    scrollWheelZoom={true}
                    className='Map'>

                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url={this.mapURL}
                    />
                    {!this.state.loading ? this.state.markers : null}
                </MapContainer>

            </div>

        );
    }
}

export default MapPage;
