import React from "react";
import { Link } from "react-router-dom";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import AttributionIcon from '@mui/icons-material/Attribution';
import Divider from '@mui/material/Divider';
import { Button, Box } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';


class Settings extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            stations: [],
            error: null,
            settings: {

                mapType: ""
            },
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {

            const currentSettings = localStorage.getItem("settings")

            if (currentSettings) {
                const settings = JSON.parse(currentSettings);
                this.setState({
                    loading: false,
                    settings: {
                        mapType: settings.mapType,

                    }

                });
            } else {
                this.setState({
                    loading: false,
                    settings: {

                        mapType: "default"
                    }
                });
                localStorage.setItem("settings", JSON.stringify({

                    mapType: "default"
                }));

            }


    };


    render() {

        const updateLS = (type, data) => {
            var newSettings = JSON.stringify({

                        "mapType": data
                    })


            localStorage.setItem("settings", newSettings);

        }


        const handleChangeMapType = (event) => {
            this.setState({
                settings: {

                    mapType: event.target.value
                }
            });
            updateLS("mapType", event.target.value);
        };

        return (
            <>
                {!this.state.loading ? <>
                    {this.state.error ? <div>{this.state.error}</div> :
                        <>
                            <List>
                                <ListItem>
                                    <ListItemText primary="Map Type" secondary={this.state.settings.mapType} />
                                    <ListItemSecondaryAction>
                                        <Select
                                            value={this.state.settings.mapType}
                                            onChange={handleChangeMapType}
                                            inputProps={{
                                                name: 'mapType',
                                                id: 'mapType-select',
                                            }}
                                        >
                                            <MenuItem value="default">Default</MenuItem>
                                            <MenuItem value="satellite">Satellite</MenuItem>
                                            <MenuItem value="terrain">Terrain</MenuItem>
                                            <MenuItem value="watercolor">Watercolor</MenuItem>
                                            <MenuItem value="dark_matter">Dark Matter</MenuItem>
                                            <MenuItem value="toner">Toner</MenuItem>
                                            <MenuItem value="tonerlite">Toner Lite</MenuItem>
                                            <MenuItem value="toner-background">Toner Background</MenuItem>
                                            <MenuItem value="toner-hybrid">Toner Hybrid</MenuItem>
                                            <MenuItem value="toner-labels">Toner Labels</MenuItem>
                                            <MenuItem value="toner-lines">Toner Lines</MenuItem>


                                        </Select>
                                    </ListItemSecondaryAction>
                                </ListItem>

                            </List>
                        </>}
                </> : <div>Loading...</div>}
            </>
        );
    }
}


export default Settings;
